import { MutableRefObject, RefObject, useRef, useState, VFC } from 'react';
import { Box, Text, Flex, Image } from '@chakra-ui/react';
import { Item, OptionItem } from 'smartdishpro-scripts/ts_common';
import { ItemDialog } from './itemDialog';
import useDevice from '../../../hooks/useDevice';
import { useAppTranslate } from '../../../hooks/useTranslation';
import { priceWSymbolItem } from '../../../utils/textFormat';
import { useStyle } from '../../../hooks/useStyle';

type Props = {
  item: Item & { defaultOptionItems: OptionItem[] };
  items: Item[];
};

export const ItemCard: VFC<Props> = ({ item, items }) => {
  const [isOpen, setIsOpen] = useState(false);
  const cancelRef = useRef() as RefObject<HTMLDivElement>;
  const { itemWidth, itemHeight } = useDevice();
  const { translate } = useAppTranslate();
  const { fontFamily } = useStyle();

  return (
    <>
      <Box
        key={item.id}
        onClick={() => (item.soldOut ? null : setIsOpen(true))}
        w={itemWidth}
      >
        <Flex w="100%" flexDirection="column">
          {item.imageUrl ? (
            <Image
              w={itemWidth}
              h={`${itemHeight * 2}px`}
              src={item.imageUrl!}
              objectFit="cover"
              borderTopRadius="lg"
            />
          ) : (
            <></>
          )}
          <Box w="100%" h={`${itemHeight}px`}>
            <Flex
              h={`${itemHeight * 0.9}px`}
              alignItems="center"
              direction="row"
              justifyContent="space-between"
              borderWidth={window.innerHeight * 0.002}
              borderColor={item.soldOut ? 'gray.100' : 'gray.50'}
              shadow={item.soldOut ? undefined : 'sm'}
              bg={item.soldOut ? 'gray.100' : 'white'}
              borderTopRadius={item.imageUrl ? undefined : 'lg'}
              borderBottomRadius="lg"
            >
              <Text
                w="70%"
                fontWeight="bold"
                fontSize="large"
                noOfLines={2}
                as={item.soldOut ? 'del' : undefined}
                paddingLeft={window.innerHeight * 0.015}
                fontFamily={fontFamily}
              >
                {translate(item.itemName)}
              </Text>
              <Flex
                w="25%"
                alignItems="flex-end"
                direction="column"
                justifyContent="end"
                paddingRight={window.innerHeight * 0.015}
              >
                <Text
                  fontWeight="semibold"
                  fontSize="md"
                  textAlign="end"
                  as={item.soldOut ? 'del' : undefined}
                  fontFamily={fontFamily}
                >
                  {priceWSymbolItem(item.price)}
                </Text>
                <Text
                  fontWeight="light"
                  fontSize="xx-small"
                  textAlign="end"
                  as={item.soldOut ? 'del' : undefined}
                  fontFamily={fontFamily}
                >
                  ( {item.priceWStandardTax} )
                </Text>
              </Flex>
            </Flex>
          </Box>
        </Flex>
      </Box>
      <ItemDialog
        item={{ ...item, okawari: false }}
        items={items}
        isOpen={isOpen}
        cancelRef={cancelRef}
        onClose={() => setIsOpen(false)}
        afterAddedCart={() => setIsOpen(false)}
      />
    </>
  );
};

type SearchedProps = Props & {
  onClose: () => void;
};

export const ItemCardSearched: VFC<SearchedProps> = ({ item, items, onClose }) => {
  const [isOpen, setIsOpen] = useState(false);
  const cancelRef = useRef() as MutableRefObject<HTMLInputElement>;
  const { translate } = useAppTranslate();
  const { fontFamily } = useStyle();

  return (
    <>
      <Box
        key={item.id}
        onClick={() => (item.soldOut ? null : setIsOpen(true))}
      >
        <Box
          shadow={item.soldOut ? undefined : 'sm'}
          borderWidth={window.innerHeight * 0.002}
          borderColor={item.soldOut ? 'gray.100' : 'gray.50'}
          bg={item.soldOut ? 'gray.100' : 'white'}
          borderRadius="lg"
        >
          <Flex
            w="100%"
            h={window.innerHeight * 0.06}
            alignItems="center"
            direction="row"
            paddingX={1}
            paddingY={2}
            justifyContent="start"
          >
            <Text
              w="20%"
              fontWeight="normal"
              fontSize="x-small"
              paddingRight="1"
              textColor="#606060"
              as={item.soldOut ? 'del' : undefined}
              fontFamily={fontFamily}
            >
              {item.itemNum}
            </Text>
            <Flex
              w="100%"
              justifyContent="space-between"
              alignItems="center"
              direction="row"
            >
              <Text
                w={`${window.innerWidth * 0.6}px`}
                h="100%"
                fontWeight="semibold"
                fontSize="medium"
                isTruncated
                as={item.soldOut ? 'del' : undefined}
                fontFamily={fontFamily}
              >
                {translate(item.itemName)}
              </Text>
            </Flex>
          </Flex>
        </Box>
      </Box>
      <ItemDialog
        item={{ ...item, okawari: false }}
        items={items}
        isOpen={isOpen}
        cancelRef={cancelRef}
        onClose={() => setIsOpen(false)}
        afterAddedCart={() => {
          setIsOpen(false);
          onClose();
        }}
      />
    </>
  );
};
